import styled from 'styled-components/macro';
import { ContentWrapper } from '../../styles';
import { console00 } from '../../styles/colors';
import { phone } from '../../styles/breakpoints';

export const FooterElem = styled.footer`
  width: 100%;
  height: 7.4rem;
  background-color: ${console00};

  @media screen and (max-width: ${phone}px) {
    height: auto;
    padding-top: 2rem;
  }
`;

export const FooterContent = styled(ContentWrapper)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${phone}px) {
    flex-direction: column;
    justify-content: center;
  }
`;
