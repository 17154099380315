import { createGlobalStyle } from 'styled-components/macro';
import { phone } from './breakpoints';

export default createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    box-sizing: border-box;
  }

  html {
    /** 1rem = 10px; 10px/16px = 62.5% **/
    font-size: 62.5%;
  }

  body {
    touch-action: manipulation;
    /** Typography **/
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    line-height: 1.4rem;
    background-color: #fff;;
    color: #202121;
    font-size: 1.5rem;
    width: 100%;
    min-height: 100vh;
  }

  main {
    padding-top: 8rem;
    
    @media screen and (max-width: ${phone}px) {
      padding-top: 2rem;
    }
  }

  a {
    color: #0553DD;
  }

  input,
  textarea {
    /* Removing styling for inputs for iOS */
    border: none;
    outline: none;
    appearance: none;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  .hidden {
    display: none;
  }
`;
