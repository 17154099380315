export const RECAPTCHA_SITE_KEY = '6LdCasEUAAAAAC5QKHxeBEn9tCKVm0SSiq7Cu59A';
export const API_CONTACT_US_URL =
  'https://techrex.azurewebsites.net/api/ContactUs?code=0NjivYMS9ymZOWADJyybrrrayeobgLOLx6KUhCd51d317AurDukAwQ%3D%3D';

export const navLinks = [
  {
    name: 'Courses',
    url: '/courses'
  },
  // {
  //   name: 'High Schools',
  //   url: '/coding-for-high-schools'
  // },
  // {
  //   name: 'Employers',
  //   url: '/employers'
  // },
  // {
  //   name: 'Blog',
  //   url: '/blog'
  // },
  // {
  //   name: 'About',
  //   url: '/about'
  // },
  {
    name: 'Contact',
    url: '/contact'
  }
];
