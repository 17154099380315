import React from 'react';
import { Link } from 'gatsby';
import { navLinks } from '../../utils/constants';
import { NavButton, MenuIcon, NavigationBackground, MobileNav } from './styles';

const MobileNavigation = () => {
  const [open, setOpen] = React.useState(false);
  const toggleMenu = React.useCallback(() => {
    setOpen(!open);
  }, [open]);
  return (
    <>
      <NavButton onClick={toggleMenu}>
        <MenuIcon isOpen={open}>&nbsp;</MenuIcon>
      </NavButton>
      <NavigationBackground isOpen={open} />
      <MobileNav isOpen={open} onClick={toggleMenu}>
        <ul>
          {navLinks.map(({ name, url }) => (
            <li>
              <Link to={url}>
                <span>/</span> {name}
              </Link>
            </li>
          ))}
        </ul>
      </MobileNav>
    </>
  );
};

export default MobileNavigation;
