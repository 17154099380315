import React from 'react';
import { Link } from 'gatsby';
import { Text, Spacing } from '../../styles';
import { stiffy1 } from '../../styles/colors';
import { FooterElem, FooterContent } from './styles';
import techrexLogoLight from '../../images/techrex-logo-light.png';

const Footer = () => (
  <FooterElem>
    <FooterContent>
      <Link to='/'>
        <img height='25px' src={techrexLogoLight} alt='TechRex Logo' />
      </Link>
      <Spacing height={8} />
      <Text color={stiffy1}>
        Teaching with{' '}
        <span role='img' aria-label='Green heart'>
          💚
        </span>{' '}
        in Johannesburg
      </Text>
    </FooterContent>
  </FooterElem>
);

export default React.memo(Footer);
