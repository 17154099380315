import styled from 'styled-components/macro';
import { Label as DefaultLabel } from './typography';
import { phone } from './breakpoints';

// Form Styles
const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FormStyles = {
  ActionsWrapper
};

// Field Styles
const Wrapper = styled.div`
  margin-bottom: 2rem;

  @media screen and (max-width: ${phone}px) {
    margin-bottom: 2rem;
  }
`;

const InlineWrapper = styled.div`
  flex: 1;
  margin-bottom: 2rem;

  &:not(:last-child) {
    margin-right: 1.6rem;

    @media screen and (max-width: ${phone}px) {
      margin-right: 0px;
    }
  }

  @media screen and (max-width: ${phone}px) {
    margin-right: 0px;
    margin-bottom: 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${phone}px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Error = styled.span`
  color: red;
  font-weight: 400;
  font-size: 1.2rem;
`;

const Label = styled(DefaultLabel)`
  margin-bottom: 4px;
`;

export const FieldStyles = {
  Row,
  Error,
  Label,
  Wrapper,
  InlineWrapper
};
