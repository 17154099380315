import React from 'react';
import { Link } from 'gatsby';
import { ContentWrapper } from '../../styles';
import { HeaderElem, DesktopNavigation } from './styles';
import techrexLogoDark from '../../images/techrex-logo-dark.png';
import MobileNavigation from './mobile-navigation';
import { navLinks } from '../../utils/constants';

const Header = () => (
  <HeaderElem>
    <ContentWrapper>
      <DesktopNavigation>
        <Link to='/'>
          <img height='25px' src={techrexLogoDark} alt='TechRex Logo' />
        </Link>
        <ul>
          {navLinks.map(({ name, url }, index) => (
            <>
              <li>
                <Link to={url}>{name}</Link>
              </li>
              {index + 1 !== navLinks.length && <li>/</li>}
            </>
          ))}
        </ul>
      </DesktopNavigation>
      <MobileNavigation />
    </ContentWrapper>
  </HeaderElem>
);

export default React.memo(Header);
