import React from 'react';
import SEO from '../seo';
import Header from '../header';
import Footer from '../footer';
import GlobalStyles from '../../styles/global-styles';

const Layout = ({ seoOptions = {}, children }) => (
  <>
    <GlobalStyles />
    <Header />
    <main>
      <SEO {...seoOptions} />
      {children}
    </main>
    <Footer />
  </>
);

export default Layout;
