import { desktop } from '../../styles/breakpoints';
import styled, { css } from 'styled-components/macro';
import { console0, console1, dinosaur0, stiffy1 } from '../../styles/colors';

export const HeaderElem = styled.header`
  width: 100%;
`;

export const DesktopNavigation = styled.nav`
  height: 6.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    display: flex;
    align-items: center;

    @media screen and (max-width: ${desktop - 1}px) {
      display: none;
    }

    li {
      display: flex;
      list-style: none;

      a {
        height: 100%;
        color: ${console0};
        line-height: 6.6rem;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0 10px;
        text-decoration: none;
        transition: all 0.2s ease-in;

        &:hover {
          color: ${dinosaur0};
        }
      }
    }
  }
`;

export const NavButton = styled.div`
  display: none;
  width: 4rem;
  height: 4rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 2200;
  border-radius: 50%;
  background-color: ${stiffy1};
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: ${desktop - 1}px) {
    display: block;
  }
`;

const OpenedStyle = css`
  & {
    background-color: transparent;
  }

  &::before {
    top: 0;
    transform: rotate(135deg);
  }

  &::after {
    top: 0;
    transform: rotate(-135deg);
  }
`;

export const MenuIcon = styled.span`
  position: relative;
  margin-top: 1.9rem;

  &,
  &::before,
  &::after {
    width: 2.3rem;
    height: 2px;
    display: inline-block;
    background-color: ${console0};
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }

  &::before {
    top: -0.8rem;
  }

  &::after {
    top: 0.8rem;
  }

  ${({ isOpen }) => isOpen && OpenedStyle}
`;

export const NavigationBackground = styled.div`
  display: none;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 2000;
  background-image: radial-gradient(${console0}, ${console1});
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);

  @media screen and (max-width: ${desktop - 1}px) {
    display: block;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(80);
    `}
`;

export const MobileNav = styled.nav`
  height: 100vh;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2100;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      width: 100%;
    `}

  ul {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    li {
      margin: 1rem;

      a {
        &:link,
        &:visited {
          font-size: 2rem;
          font-weight: 300;
          padding: 1rem 2rem;
          color: ${stiffy1};
          text-decoration: none;
          text-transform: uppercase;
          background-image: linear-gradient(
            120deg,
            transparent 0%,
            transparent 50%,
            ${stiffy1} 50%
          );
          background-size: 230%;
          transition: all 0.4s;
          display: inline-block;

          span {
            margin-right: 1.5rem;
            display: inline-block;
          }
        }
      }
    }
  }
`;
