import styled from 'styled-components/macro';
import { dinosaur0 } from './colors';
import { phone } from './breakpoints';
export * from './typography';
export * from './form';

export const Section = styled.section`
  padding: 7rem 6rem;

  @media screen and (max-width: ${phone}px) {
    padding: 7rem 1rem;
  }
`;

export const ContentWrapper = styled.div`
  margin: 0 auto;
  padding: 0 6.4rem;

  @media screen and (max-width: ${phone}px) {
    padding: 0 3rem 4rem;
  }
`;

export const CenteredContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 80rem;
`;

export const CenteredWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 110rem;
`;

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const Spacing = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const Highlighted = styled.span`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: ${dinosaur0};
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
