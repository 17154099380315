import styled, { css } from 'styled-components/macro';
import { tablet, phone } from './breakpoints';
import { console0 } from './colors';

export const TextBaseStyle = css`
  backface-visibility: hidden;
  font-family: 'Work Sans', sans-serif;
  color: ${({ color }) => color || console0};
  text-align: ${({ align }) => align || 'left'};
`;

export const Heading = styled.h1`
  ${TextBaseStyle}
  font-size: 4rem;
  font-weight: 700;
  line-height: 5.5rem;

  @media screen and (max-width: ${tablet}px) {
    font-size: 2.8rem;
    line-height: 3rem;
  }

  @media screen and (max-width: ${phone}px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;

export const Title = styled.h2`
  ${TextBaseStyle}
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 3rem;

  @media screen and (max-width: ${phone}px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const Subheading = styled.h3`
  ${TextBaseStyle}
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.8rem;
`;

export const Subtitle = styled.h3`
  ${TextBaseStyle}
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 2rem;

  @media screen and (max-width: ${phone}px) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
`;

export const Text = styled.p`
  ${TextBaseStyle}
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export const Description = styled(Text)`
  color: #999999;
  font-size: 1.3rem;
`;

export const Label = styled.p`
  ${TextBaseStyle}
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  backface-visibility: hidden;
`;

export const FootNote = styled.p`
  ${TextBaseStyle}
  font-size: 1.1rem;
  line-height: 1.2rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;
