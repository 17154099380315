export const dinosaur0 = '#BDF560';
export const dinosaur1 = '#A9E14C';
export const dinosaur2 = '#95CD38';

export const console00 = '#1c2124';
export const console0 = '#242F35';
export const console1 = '#606B71';
export const console2 = '#9CA7AD';

export const gemstone0 = '#60ECF5';
export const gemstone1 = '#4CD8E1';
export const gemstone2 = '#24B0B9';

export const stiffy = '#d6e5e5';
export const stiffy0 = '#ECF5F5';
export const stiffy1 = '#F6FAFA';

export const sunshine0 = '#FFF383';
export const sunshine1 = '#E9DB64';
